// const accesstrucksAggregatePage_mediaNet = [
//   { adSlotId: '191315324', adSize: '320x250' }
// ]
// export { accesstrucksAggregatePage_mediaNet }
const adsByPartners = {
  // eCapital: {
  //   'freight factoring': {
  //     filePath: 'factoring',
  //     phoneNumber: '+18885214151',
  //     span: {
  //       emailButtonText: 'Agente de factoraje de correo electrónico!',
  //       callButtonText: 'Agente de factoraje de llamadas'
  //     },
  //     en: {
  //       emailButtonText: 'Email Factoring Agent for a free quote!',
  //       callButtonText: 'Call Factoring Agent'
  //     }
  //   }
  // },
  mazoCapital: {
    financing: {
      filePath: 'financing',
      phoneNumber: '+18886981989'
    }
  }
}
export { adsByPartners }
