<template>
  <div class="truck-details">
    <div v-if="fromAggregate" class="truck-title">{{ truckInfo.title }}</div>
    <h1 v-else class="truck-title">{{ truckInfo.title }}</h1>
    <div v-if="secondValue" class="payment-details">
      {{ secondValue || '' }}
    </div>
    <div class="truck-location">{{ truckInfo.location }}</div>
    <template v-if="fromAggregate">
      <div class="payment-details">
        {{ truckInfo.details || '' }}
      </div>
    </template>
    <div v-else-if="truckInfo.details" class="truck-mileage-model-horsepower">
      {{ truckInfo.details }}
    </div>
    <!-- <div style="height:20px" v-else /> -->
    <div class="truck-price">{{ fifthValue }}</div>
  </div>
</template>

<script>
  export default {
    name: 'TruckInfo',
    props: {
      truckInfo: Object,
      secondValue: String,
      fifthValue: String,
      fromAggregate: { type: Boolean, default: true }
    }
  }
</script>

<style lang="scss">
  .truck-details {
    color: $gray-5;
    padding: 6px;
    .payment-details {
      color: $m-green;
    }
    .truck-price {
      color: $m-red;
    }
  }
</style>
