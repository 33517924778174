//leadType, targetTruck, message, siteName
import buildLeadBody from '../sharedHelpers/buildLeadBody'
import leadRequest from './leadRequest'
import gtag from '../sharedHelpers/gtag'

export default async (targetVueComponent, leadDetail) => {
  let profile = targetVueComponent.$store.getters.getProfile,
    marketingSource = targetVueComponent.$route.query.m || 'misc',
    leadType = leadDetail.leadType,
    { truckId, dealerId } = leadDetail.targetTruck
  if (targetVueComponent.$store.getters.profileValid) {
    try {
      /* {buyerID,status}*/
      let res
      //block this email address
      if (profile.email === 'joseangel305896@gmail.com') {
        res = { status: true }
      } else {
        res = (
          await leadRequest(
            buildLeadBody({
              profile,
              leadType,
              message: leadDetail.message,
              truckId,
              dealerId,
              marketingSource,
              shouldSendExtraLeads: leadDetail.shouldSendExtraLeads
            }),
            leadDetail.siteName
          )
        ).data
      }
      if (res.status) {
        if (leadType === 'email') {
          targetVueComponent.$store.commit('addAlert', {
            msg: 'Email sent to dealer!',
            msgType: 'success'
          })
          if (targetVueComponent.targetTruckForEmailLead)
            targetVueComponent.targetTruckForEmailLead = null
          if (targetVueComponent.popupSwitch.showProfilePopup)
            targetVueComponent.popupSwitch.showProfilePopup = false
          else if (targetVueComponent.popupSwitch.showMsgPopup)
            targetVueComponent.popupSwitch.showMsgPopup = false
          targetVueComponent.$store.commit('addEmailedUnit', {
            id: truckId,
            emailDate: new Date().toLocaleDateString()
          })
        }
        if (!profile.buyerId) {
          targetVueComponent.$store.commit('updateProfile', {
            buyerId: res.buyerID
          })
        }
      }
      // {errors:{truckErrors,truckClientErrors}, status}
      else if (leadType === 'email')
        targetVueComponent.$store.commit('addAlert', {
          msg: 'Sorry, this truck has just been sold or removed!',
          msgType: 'warning'
        })
    } catch (error) {
      if (leadType === 'email')
        targetVueComponent.$store.commit('addAlert', {
          msg: 'Sorry, something went wrong! Please try again later!',
          msgType: 'warning'
        })
    }
  }
  gtag('event', `${marketingSource}_leads`, {
    event_category: leadType,
    event_label: truckId
  })
  let marketTruckId = targetVueComponent.$store.getters.getMarketTruckId
  if (truckId === marketTruckId) {
    gtag('event', `${marketingSource}_single_ad_leads`, {
      event_category: leadType,
      event_label: truckId
    })
  }
}
