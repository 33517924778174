export default {
  state: {
    profile: { creditScore: '', state: '' },
    emailedUnits: {}
  },
  getters: {
    getEmailedUnits: (state) => state.emailedUnits,
    profileValid: (state) => !!state.profile.firstName,
    getProfile: (state) => state.profile,
    getSessionId: (state) => state.profile.sessionId
  },
  mutations: {
    updateProfile(state, newProfile) {
      state.profile = {
        ...state.profile,
        ...newProfile
      }
      window.localStorage.setItem('profile', JSON.stringify(state.profile))
    },
    deleteProfile(state) {
      state.profile = { creditScore: '', state: '' }
      window.localStorage.removeItem('profile')
    },
    addEmailedUnit(state, newUnit) {
      state.emailedUnits = {
        ...state.emailedUnits,
        [newUnit.id]: newUnit.emailDate
      }
    }
  }
  // actions: {},
}
