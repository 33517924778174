<template>
  <div class="truck-card">
    <router-link
      :to="individualTruckUrl"
      @click.capture.prevent.stop="viewIndividualTruck"
    >
      <ImageBlock
        :imgConfig="{
          clientID: truckInfo.dealerid,
          picName: truckInfo.pics[0],
          unitID: truckInfo._id,
          alt: imgAlt
        }"
      />
      <div>
        <TruckInfo
          :truckInfo="truckInfo"
          :secondValue="secondValue"
          :fifthValue="fifthValue"
        />
      </div>
    </router-link>
    <div class="call-and-email">
      <a
        class="call-btn"
        :href="`tel:${truckInfo.dealerphone}`"
        @click.stop="
          $emit('lead-request', {
            leadType: truckLeadTypes.call,
            targetTruck: truckInfo
          })
        "
        :style="vw > 420 ? 'text-align: center' : ''"
      >
        {{ vw > 420 ? truckInfo.dealerphone : 'Call' }}
      </a>
      <div class="email-btns">
        <div v-if="emailed" @click.stop class="emailed-btn"></div>
        <button v-else class="email-btn" @click.stop="emitSendEmail">
          Email
        </button>
      </div>
    </div>
  </div>
</template>
<script>
  //_id, urlTitle, pics,dealerphone, dealeremail,dealerid
  // {dealer:[
  //   title, defaultPayment,location,details,price
  //   ],
  //   lessor:[
  //   title, defaultPayment,location,details,downPayment
  //   ],
  // auctioneer:[
  //   title, dealername,location,details,startdate
  // ]}
  // import httpRequest from '../../../sharedHelpers/httpRequest'
  import { getUnitName } from '../../../sharedHelpers/getDocumentTitleAndDescription'
  import { siteName } from '../config/siteSpecificConfig'
  import TruckInfo from '@/components/TruckInfo'
  import ImageBlock from '../../../sharedComponents/ImageBlock'
  import {
    truckLeadTypes,
    truckTypesAndRoutesMap
  } from '../../../sharedConfigs/miscConfig'
  export default {
    components: { TruckInfo, ImageBlock },
    data: () => {
      return {
        secondValue: null,
        fifthValue: null,
        truckLeadTypes,
        imgAlt: null,
        individualTruckUrl: null
      }
    },
    computed: {
      emailed: function () {
        return this.$store.getters.getEmailedUnits[this.truckInfo._id]
      }
    },
    emits: ['toggle-pop-up', 'lead-request', 'view-individual-truck'],
    async created() {
      await this.setUpPage()
    },
    // watch: {
    //   $route: async function() {
    //     await this.setUpPage()
    //   }
    // },
    name: 'TruckCard',
    props: {
      truckInfo: Object,
      truckType: String,
      vw: Number
    },
    methods: {
      async setUpPage() {
        let truckTypeConfig = truckTypesAndRoutesMap[this.truckType]
        this.individualTruckUrl = `/trucks-${truckTypeConfig.route}/${this.truckInfo.urlTitle}/${this.truckInfo._id}`
        this.imgAlt = `${this.truckInfo.year} ${
          this.truckInfo.manufacturer
        } ${getUnitName(this.truckInfo.category, '', siteName, false)} ${
          truckTypeConfig.salesType
        } In ${this.truckInfo.state}`
        if (this.truckType !== 'auctioneer') {
          this.secondValue = this.truckInfo.defaultPayment
          // this.secondValue = ''
          if (this.truckType === 'dealer')
            this.fifthValue = this.truckInfo.price
          else this.fifthValue = this.truckInfo.downPayment
          // else this.fifthValue = ''
        } else {
          this.secondValue = this.truckInfo.dealername
          this.fifthValue = this.truckInfo.startdate
        }
        // if (this.truckInfo.pics.length) {
        //   // this.imageUrl = `${API_S3.url}/${this.truckInfo.dealerid}/${this.truckInfo.pics[0]}`
        //   try {
        //     let response = await httpRequest(
        //       {
        //         method: 'get',
        //         url: `${API_S3.url}/${this.truckInfo.dealerid}/${this.truckInfo.pics[0]}`,
        //         responseType: 'blob',
        //       },
        //       undefined,
        //       false
        //     )
        //     if (response.status === 200)
        //       this.imageUrl = URL.createObjectURL(response.data)
        //   } catch (err) {
        //     if (err.response.status !== 404) console.log(err.response.status)
        //   }
        // }
      },
      // revokeObjectURL() {
      //   URL.revokeObjectURL(this.imageUrl)
      // },
      emitSendEmail() {
        let targetKey = this.$store.getters.profileValid
          ? //don't need fill out profile
            'showMsgPopup'
          : //need fill out profile
            'showProfilePopup'
        this.$emit('toggle-pop-up', {
          targetTruck: this.truckInfo,
          targetKey,
          value: true
        })
      },
      viewIndividualTruck() {
        this.$emit('view-individual-truck')
        this.$router.push(this.individualTruckUrl)
      }
    }
  }
</script>

<style lang="scss">
  .truck-card {
    position: relative;
    min-width: 130px;
    border-radius: 0.5rem;
    box-shadow: 0 2px 3px 0 $gray-3;
    background-color: $white;
    margin: 6px;
    cursor: pointer;
    .img-div {
      padding-top: 75%;
      width: 100%;
      @include centerBackground;
      background-color: $gray-3;
    }
    .truck-details {
      @include font;
      text-align: center;
      & > * {
        line-height: 20px;
        @include textOverflow;
      }
      .payment-details {
        height: 20px;
      }
      .truck-title,
      .truck-price {
        font-size: 16px;
      }
    }
  }
  .highlight {
    box-shadow: 0px 2px 8px $at-red;
    background-color: $red-light;
  }

  .call-and-email {
    display: flex;
    .call-btn,
    .email-btns {
      width: 50%;
    }
    .call-btn,
    .email-btn,
    .emailed-btn {
      color: $white;
      @include font(14px);
      @include borderAndBackgroundColor($at-red);
      @include centerBackground(36%);
    }
    .call-btn {
      @include centerBackground(0);
      padding: 6px 6px 6px 25px;
      background-image: url($icon-white-call);
    }
    .email-btns {
      display: flex;
      & > * {
        width: 100%;
      }
    }
    .email-btn {
      text-align: left;
      padding-left: 50%;
      background-image: url($icon-white-email);
    }
    .emailed-btn {
      background-size: 24px;
      background-image: url($icon-white-email-read);
      background-position-x: center;
      background-color: $m-pink;
    }
  }

  @media screen and (max-width: 420px) {
    .call-and-email {
      .call-btn,
      .emailed-btn,
      .email-btn {
        font-size: 13px;
        background-size: 18px;
        padding-top: 7px;
        padding-bottom: 7px;
      }
      .call-btn {
        background-position: 28%;
        padding-left: 23%;
      }
      .email-btn {
        background-position: 20%;
        padding-left: 42%;
      }
    }
    .truck-card {
      .truck-details {
        padding: 0 6px 3px 6px;
        & > * {
          line-height: 18px;
        }
        .payment-details {
          height: 18px;
        }
        .truck-title,
        .truck-price {
          font-size: 14px;
        }
      }
    }
  }
</style>
