const siteName = 'accesstrucks',
  terms = {
    cookiePolicy: {
      text: 'Cookie Policy',
      url:
        'https://app.termly.io/document/cookie-policy/af042042-2e42-4d2b-a1ed-fc6b3b9b7494'
    },
    termOfUse: {
      text: 'Terms of Use',
      url:
        'https://app.termly.io/document/terms-of-use-for-website/282f269c-f1f8-4fd0-a14a-5c1c01951435'
    },
    privacyPolicy: {
      text: 'Privacy Policy',
      url:
        'https://app.termly.io/document/privacy-policy/8062d563-986f-4849-b0f3-708c980adb95'
    }
  },
  GA_TRACKING_ID = 'UA-111413542-2'

export { siteName, terms, GA_TRACKING_ID }
