// const sleeperTraderPaymentDisclaimer = {
//   dealer:
//     '*This is a payment estimate only based on a 20% down payment at 19.99% APR.  All financing products are negotiated between you and your chosen lender which will cause your actual payment to be higher or lower.  SleeperTrader.com does not offer financing.',
//   lessor:
//     '*These are payment estimates provided by each advertiser.  Your specific contract will determine your actual payment which may be higher or lower.  Please seek competent legal counsel prior to signing any contract.  SleeperTrader.com does not offer financing.'
// }
// const daycabTraderPaymentDisclaimei = {
//   dealer:
//     '*This is a payment estimate only based on a 20% down payment at 19.99% APR.  All financing products are negotiated between you and your chosen lender which will cause your actual payment to be higher or lower.  daycabtrader.com does not offer financing.',
//   lessor:
//     '*These are payment estimates provided by each advertiser.  Your specific contract will determine your actual payment which may be higher or lower.  Please seek competent legal counsel prior to signing any contract.  daycabtrader.com does not offer financing.'
// }
// const accessTrucksPaymentDisclaimei = {
//   dealer:
//     '*This is a payment estimate only based on a 20% down payment at 19.99% APR.  All financing products are negotiated between you and your chosen lender which will cause your actual payment to be higher or lower.  accesstrucks.com does not offer financing.',
//   lessor:
//     '*These are payment estimates provided by each advertiser.  Your specific contract will determine your actual payment which may be higher or lower.  Please seek competent legal counsel prior to signing any contract.  accesstrucks.com does not offer financing.'
// }
// export {
//   sleeperTraderPaymentDisclaimer,
//   daycabTraderPaymentDisclaimei,
//   accessTrucksPaymentDisclaimei
// }
export default (siteName) => ({
  dealer: `*This is strictly an estimate of what your lender may be able to offer you based on a 20% cash or trade down payment and a 19.99% rate.  All negotiations with your bank will likely cause your actual payment to be higher or lower.  ${siteName}.com does not offer financing.`,
  lessor: `*These are payment estimates provided by each advertiser.  Your specific contract will determine your actual payment which may be higher or lower.  Please seek competent legal counsel prior to signing any contract.   ${siteName}.com does not offer financing.`
})
