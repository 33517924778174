<template>
  <div class="disclaimer">
    <span>&copy;{{ year }} Access Publishing, Inc.</span>
    <br />
    <!-- <a
      class="terms-link"
      v-for="term in terms"
      :key="term.text"
      :href="term.url"
      target="blank"
      ><span v-if="term.text === 'Cookie Policy'">{{ term.text + ', ' }}</span>
      <span v-else-if="term.text === 'Terms of Use'">{{
        term.text + ' and '
      }}</span>
      <span v-else>{{ term.text }}</span>
    </a> -->
    <a class="terms-link" :href="terms.cookiePolicy.url" target="blank">{{
      terms.cookiePolicy.text
    }}</a
    >,
    <a class="terms-link" :href="terms.termOfUse.url" target="blank">{{
      terms.termOfUse.text
    }}</a>
    and
    <a class="terms-link" :href="terms.privacyPolicy.url" target="blank">{{
      terms.privacyPolicy.text
    }}</a>
  </div>
</template>

<script>
  import { terms } from '../config/siteSpecificConfig'
  export default {
    name: 'Disclaimer',
    data() {
      return {
        year: new Date().getFullYear(),
        terms,
      }
    },
  }
</script>

<style lang="scss">
  .disclaimer {
    @include containerWidth(285px, 960px);
    @include containerPosition(relative, 0);
    padding-bottom: 6px;
    padding-top:7px;
    text-align: center;
    @include font;
    color: $gray-4;
    .terms-link {
      color: $m-red;
    }
  }

  @media screen and (max-width: 800px) {
    .disclaimer {
      padding-bottom: 56px;
    }
  }
</style>
