<template>
  <p v-if="disclaimerText" class="payment-disclaimer">
    {{ disclaimerText }}
  </p>
</template>

<script>
  import paymentDisclaimer from '../sharedConfigs/paymentDisclaimer'
  export default {
    data: () => {
      return {
        allDisclaimerTexts: null
      }
    },
    props: {
      truckType: String,
      siteName: String
    },
    created() {
      this.allDisclaimerTexts = paymentDisclaimer(this.siteName)
    },
    computed: {
      disclaimerText: function () {
        return this.allDisclaimerTexts[this.truckType]
      }
    }
  }
</script>

<style lang="scss">
  .payment-disclaimer {
    @include payment-disclaimer;
  }
</style>
