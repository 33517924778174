<template>
  <div class="auction-card">
    <div class="auction-dealer-name">
      {{ auction.name }}
    </div>
    <div class="auction-location">
      {{ location }}
    </div>
    <div class="auction-date">{{ auctionDate }}</div>
    <div class="dealer-related-btns">
      <router-link
        :to="`/trucks-by-dealer/${auction._id}?startdate=${startdate}&page=1`"
        class="view-dealer-units"
      >
        View Dealer Units
      </router-link>
      <a
        v-if="auction.weburl"
        :href="auction.weburl"
        target="_blank"
        class="view-dealer-website"
      >
        View Dealer Website
      </a>
    </div>
  </div>
</template>
<script>
  export default {
    computed: {
      location: function () {
        let location = '',
          { mailingaddress } = this.auction,
          { city, state } = mailingaddress
        if (city && state) location = `${city}, ${state}`
        else mailingaddress += city + state
        return location || 'N/A'
      },
      auctionDate: function () {
        let { dates } = this.auction
        return `${new Date(dates.startdate).toLocaleDateString()} - ${new Date(
          dates.enddate
        ).toLocaleDateString()}`
      },
      startdate: function () {
        let { dates } = this.auction,
          date = new Date(dates.startdate),
          dateString = `${date.getDate()}`
        if (dateString.length === 1) dateString = '0' + dateString
        return `${dateString}-${date.toLocaleString('en-US', {
          month: 'short'
        })}-${date.getFullYear()}`
      }
    },
    name: 'ActionCard',
    props: {
      auction: Object
    }
  }
</script>

<style lang="scss">
  .auction-card {
    position: relative;
    min-width: 130px;
    border-radius: 0.5rem;
    box-shadow: 0 2px 3px 0 $gray-3;
    background-color: $white;
    margin: 6px;
    cursor: pointer;
    text-align: center;
    background-image: linear-gradient(to left, $gray-2, $white);
    text-shadow: 1px 1px 2px $m-blue;
  }

  .auction-dealer-name,
  .auction-location,
  .auction-date,
  .view-dealer-units,
  .view-dealer-website {
    @include font(20px);
    padding: 11px;
  }
  .auction-dealer-name {
    background-color: $dark-1;
    color: $white;
    font-size: 20px;
    border-radius: 0.5rem 0.5rem 0 0;
  }
  .auction-location {
    color: $at-red;
  }
  .auction-date {
    color: $m-red;
  }

  .view-dealer-units,
  .view-dealer-website {
    border-radius: 0.5rem;
    width: 47%;
    color: $white;
    font-size: 16px;
    line-height: 30px;
    padding: 3px;
    margin: 12px auto;
    display: block;
    background-color: $at-red;
  }
  @media screen and (min-width: 420px) {
    .dealer-related-btns {
      display: flex;
    }
  }
  @media screen and (max-width: 419px) {
    .view-dealer-units,
    .view-dealer-website {
      width: 60%;
    }
  }
</style>
