import { createRouter, createWebHistory } from 'vue-router'
import AggregateTrucks from '../views/AggregateTrucks'
// import IndividualTruck from '../views/IndividualTruck'
// import Profile from '../views/Profile'
import AuctionLists from '../views/AuctionLists'
import { truckTypesAndRoutesMap } from '../../../sharedConfigs/miscConfig'
import { adsByPartners } from '../config/adsConfig'

let validatePath = (to, from, next) => {
    let page = to.query.page
    if (page) {
      let sanitizedPage = page.replace(/[^0-9]/g, '')
      //there are non-digit chars
      if (sanitizedPage !== page) {
        if (sanitizedPage) to.query.page = parseInt(sanitizedPage) || 1
        else to.query.page = 1
        next(to)
      } else {
        let parsedPage = parseInt(page) || 1
        if (page !== `${parsedPage}`) {
          to.query.page = parsedPage
          next(to)
        } else next()
      }
    } else {
      to.query.page = 1
      next(to)
    }
  },
  routesUsingTruckTypes = []
for (let key in truckTypesAndRoutesMap) {
  routesUsingTruckTypes.push(
    {
      path: `/trucks-${truckTypesAndRoutesMap[key].route}`,
      component: AggregateTrucks,
      props: { truckType: key },
      beforeEnter: validatePath
    },
    {
      path: `/trucks-${truckTypesAndRoutesMap[key].route}/:urlTitle/:truckId`,
      component: () => import('../views/IndividualTruck'),
      // component: IndividualTruck,
      props: (route) => ({
        truckType: key,
        truckId: route.params.truckId
      })
    }
  )
}
const routes = [
  {
    path: '/',
    beforeEnter: (from, to, next) => {
      next({ path: '/trucks-for-sale?page=1', query: from.query })
    }
  },
  {
    path: '/profile',
    component: () => import('../views/Profile')
  },
  {
    path: '/auction-lists',
    component: AuctionLists
  },
  // {
  //   path: '/faq',
  //   component: () => import('../views/FAQ'),
  // },
  // {
  //   path: '/inquired-units',
  //   component: () => import('../views/IndividualTruck')
  //   // component: IndividualTruck,
  // },
  ...routesUsingTruckTypes,
  {
    path: '/trucks-by-dealer/:dealerId',
    component: AggregateTrucks,
    props: true,
    beforeEnter: validatePath
  },
  {
    path: '/offers/:partner/:partnerAdType',
    beforeEnter: (to, from, next) => {
      let { partner, partnerAdType } = to.params
      partner = partner.replace(/\s+/g, '')
      partnerAdType = partnerAdType.replace(/\s+/g, '')
      if (
        adsByPartners[partner] &&
        adsByPartners[partner][partnerAdType.replace(/-/g, ' ')]
      ) {
        to.params.partner = partner
        to.params.partnerAdType = partnerAdType
        next()
      } else next('/')
    },
    component: () => import('../../../sharedComponents/PartnerAdPage'),
    children: [
      {
        path: '',
        //make this dynamic if there are more than one partner
        components: {
          // eCapital: () => import('../components/EcapitalLanding.vue')
          // otrCapital: otrCapitalLanding
          mazoCapital: () => import('../components/mazoCapitalLanding')
        }
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('../views/NotFound')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      if (savedPosition) {
        setTimeout(() => {
          savedPosition.behavior = 'smooth'
          resolve(savedPosition)
        }, 0)
      } else {
        setTimeout(() => {
          resolve({ left: 0, top: 0 })
        }, 0)
      }
    })
  }
})
router.beforeEach((to, from, next) => {
  // if (to.fullPath === from.fullPath) next(false)
  let { m } = from.query
  if (m && to.query.m !== m) {
    next({
      ...to,
      query: {
        ...to.query,
        m
      }
    })
  } else next()
})
export default router
