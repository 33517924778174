<template>
  <div class="pagination-container">
    <router-link
      :to="previousPageRoute"
      class="back-btn"
      :class="{ disabled: page === 1 }"
    >
      <span> Back</span>
    </router-link>
    <select
      name="page-number"
      id="page"
      v-model="page"
      @change="switchPage(page)"
    >
      <option v-for="n in pages" :key="n" :value="n">{{ n }}</option>
    </select>
    <router-link
      :to="nextPageRoute"
      class="next-btn"
      :class="{ disabled: page === pages }"
    >
      <span> Next</span>
    </router-link>
  </div>
</template>

<script>
  export default {
    name: 'Pagination',
    props: {
      pages: Number
    },
    data: () => {
      return {
        page: null,
        nextPageRoute: null,
        previousPageRoute: null
      }
    },
    created() {
      let currentPage = parseInt(this.$route.query.page)
      this.page =
        currentPage >= 0 && currentPage <= this.pages ? currentPage : null
      this.nextPageRoute = {
        path: this.$route.path,
        query: {
          ...this.$route.query,
          page: this.page + 1 <= this.pages ? this.page + 1 : this.page
        }
      }
      this.previousPageRoute = {
        path: this.$route.path,
        query: {
          ...this.$route.query,
          page: this.page - 1 > 0 ? this.page - 1 : this.page
        }
      }
    },
    methods: {
      switchPage(page) {
        if (page > 0 && page <= this.pages) {
          this.page = page
          this.$router.push({
            path: this.$route.path,
            query: {
              ...this.$route.query,
              page: this.page
            }
          })
        }
      }
    }
    // watch: {
    //   $route: function() {
    //     //fall back in case create hook is not called
    //     this.page = parseInt(this.$route.query.page)
    //     this.nextPageRoute = {
    //       path: this.$route.path,
    //       query: {
    //         ...this.$route.query,
    //         page: this.page + 1 <= this.pages ? this.page + 1 : this.page
    //       }
    //     }
    //     this.previousPageRoute = {
    //       path: this.$route.path,
    //       query: {
    //         ...this.$route.query,
    //         page: this.page - 1 > 0 ? this.page - 1 : this.page
    //       }
    //     }
    //   }
    // }
  }
</script>

<style lang="scss">
  .pagination-container {
    position: relative;
    display: grid;
    grid-template-columns: 35% 30% 35%;
    & > * {
      color: $white;
      background-color: $at-red;
    }
    .back-btn,
    .next-btn {
      @include font(16px);
      height: 39px;
      margin: auto 5px;
      position: relative;
      span {
        position: absolute;
        top: 51%;
        transform: translate(-50%, -50%);
      }
    }

    select {
      @include backgroundAndPosition($icon-white-arrow-drop, right);
      padding-left: 33px;
      height: 42px;
    }
  }
  .back-btn {
    border-radius: 1rem 0.5rem 0.5rem 1rem;
    @include backgroundAndPosition($icon-white-chevron-left, left 8px);
    padding-left: 6px;
    span {
      left: 55%;
    }
  }

  .next-btn {
    border-radius: 0.5rem 1rem 1rem 0.5rem;
    @include backgroundAndPosition($icon-white-chevron-right, right 8px);
    padding-right: 6px;
    span {
      left: 45%;
    }
  }
  .disabled {
    background-color: $gray-3;
  }

  @media screen and (max-width: 420px) {
    .pagination-container {
      .back-btn,
      .next-btn {
        @include font(14px);
        height: 33px;
        background-position-y: 5px;
      }
      select {
        height: 36px;
      }
    }
  }
</style>
