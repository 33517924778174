//key rule(aggregation page only)
//pageType_filterKey(index) + filterValue(index or value)_

// { keyOrder, categoryFilters, manufacturerFilters }
// import * as siteSpecificFilters from '../config/filterConfig'
// {hasImageFilters,horsepowerFilters,priceFilters,stateFilters,yearFilters}
import * as commonFilters from '../sharedConfigs/filterConfig'
export default (targetVueComponent, siteSpecificFilters) => {
  let pageType = targetVueComponent.truckType || targetVueComponent.dealerId,
    query = targetVueComponent.$route.query
  let queryKeyInOrder = Object.keys(query).sort((a, b) => {
    if (a < b) return -1
    if (a > b) return 1
    return 0
  })
  let keyString = `${pageType}_`,
    keysAddValueOnly = { mUnitId: true, page: true, model: true },
    keyOrder = siteSpecificFilters.keyOrder
  for (let i = 0; i < queryKeyInOrder.length; i++) {
    let queryKey = queryKeyInOrder[i]
    if (keyOrder[queryKey]) {
      if (keysAddValueOnly[queryKey])
        keyString += `${keyOrder[queryKey]}${query[queryKey]}_`
      else if (queryKey === 'horsepower') {
        let horsepower = query.horsepower
        if (!horsepower.includes('-')) {
          let hpRange = Object.keys(commonFilters['horsepowerFilters'])
          for (let j = 0; j < hpRange.length; j++) {
            let ithHpRange = hpRange[j].split('-')
            if (horsepower >= ithHpRange[0] && horsepower <= ithHpRange[1]) {
              keyString += `${keyOrder[queryKey]}${j}_`
              break
            }
          }
        } else
          keyString += `${keyOrder[queryKey]}${Object.keys(
            commonFilters['horsepowerFilters']
          ).indexOf(query[queryKey])}_`
      } else {
        if (queryKey === 'year') {
          let index = Object.keys(
            targetVueComponent.$store.getters.getYearFilters(pageType)
          ).indexOf(`${query[queryKey]} `)
          if (index >= 0) keyString += `${keyOrder[queryKey]}${index}_`
        }
        //everything else
        else if (siteSpecificFilters[`${queryKey}Filters`]) {
          let index = Object.keys(
            siteSpecificFilters[`${queryKey}Filters`]
          ).indexOf(query[queryKey])
          if (index >= 0) keyString += `${keyOrder[queryKey]}${index}_`
        } else if (commonFilters[`${queryKey}Filters`]) {
          let index = Object.keys(commonFilters[`${queryKey}Filters`]).indexOf(
            query[queryKey]
          )
          if (index >= 0) keyString += `${keyOrder[queryKey]}${index}_`
        }
      }
    } else if (queryKey == 'startdate') {
      keyString += `${query[queryKey]}_`
    }
  }
  return keyString.slice(0, -1)
}
